import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-cv-form',
  templateUrl: './cv-form.component.html',
  styleUrls: ['./cv-form.component.css']
})
export class CvFormComponent implements OnInit {
  showForm: boolean = false;
  showHearAboutMe: boolean = false;
  showOpportunityType: boolean = false;
  urlValidator = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  cvForm: FormGroup;
  opportunityTypes = ['W2', 'Contract/Freelance', 'Partner/Equity']

  constructor() { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.cvForm = new FormGroup({
      'hearAboutMe': new FormControl(null, Validators.min(3)),
      'opportunityType': new FormControl(null, Validators.required),
      'description': new FormControl(null, Validators.min(3)),
      'link': new FormControl('http://', [Validators.required])
    });
  };

  onHearAboutMe(){
    this.showHearAboutMe = !this.showHearAboutMe;
    this.showOpportunityType = !this.showOpportunityType;
  }

  onSubmit() {
    console.log(this.cvForm)
  };

}
